.SearchBar {
  margin-top: 1.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

._searchButton {
  font-size: 24px;
  font-family: "Changa";
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  align-items: center;
  background-color: aliceblue;
  border: 1px solid darkblue;
  border-radius: 0px 25px 25px 0px;
  height: 46px;
}

._searchButton:hover {
  cursor: pointer;
  background-color: aquamarine;
}

._searchButton:active {
  background-color: blueviolet;
}

._searchButton img {
  max-width: 1em;
  margin-left: 0.5em;
}

._searchInput {
  width: 60%;
  padding-left: 1em;
  height: 42px;
  border-radius: 25px 0px 0px 25px;
  border: 1px solid darkblue;
}

@media screen and (max-width: 650px) {
  #redditit {
    display: none;
  }

  ._searchButton {
    padding-left: 0em;
    padding-right: 0.5em;
  }
}
