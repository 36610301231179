.App {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  padding-top: 10em;
}

._icon {
  max-width: 300px;
  max-height: 300px;
}

._searchBarContainer {
  background-color: white;
  border-radius: 10px;
  width: 40em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 1px 5px rgba(10, 9, 9, 0);
}
